import { AxiosResponse } from 'axios';
import requests from 'lib/request';
import { ResponsePage } from 'types/base';
import { MapGeocodeReversed, MapLocation, MapSearchPayload, MapSearchAddress } from 'types/map';
import gcoord from 'gcoord';
import { DEFAULT_PAGE_SIZE } from 'config/settings';

class Map {

  gcj02towgs84 = (location: MapLocation): MapLocation => {
    const [ lng, lat ] = gcoord.transform([location.lng, location.lat], gcoord.GCJ02, gcoord.WGS84);
    return {lat, lng, type: gcoord.WGS84};
  }

  search = ({ location, keyword, page, size = DEFAULT_PAGE_SIZE }: MapSearchPayload) => {
    return requests.get<MapSearchPayload, AxiosResponse<ResponsePage<MapSearchAddress>>>(`/map/search/${location.lat},${location.lng}`, { params: { keyword: keyword, page, size } })
      .then(res => {
        res.data.data = res.data.data.map(item => {
          if(item.location.type===gcoord.GCJ02) {
            item.location = this.gcj02towgs84(item.location);
          }
          return item
        })
        return res
      })
  }

  // geocoder = ({location, address}: MapGeocodePayload) => {
  //   return requests.get<MapGeocode>(`/map/geocoder/${location.lat},${location.lng}/${address}`).then(res=>res.data)
  // }

  geocoder_reverse = ({ lat, lng }: Omit<MapLocation, "type">) => {
    return requests.get<MapGeocodeReversed>(`/map/geocoder_reverse/${lat},${lng}`)
  }

  get_static_map_url = (lat: number, lng: number, zoom = 13, width = 300, height = 200) => {
    return `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+555555(${lng},${lat})/${lng},${lat},${zoom},0/${width}x${height}@2x?before_layer=admin-0-boundary&access_token=pk.eyJ1Ijoid2FuZ2NvZGUiLCJhIjoiY2tpbWx1ZHFzMGxvczJ6b2FwbHg4MG54biJ9.Lhmn_bHKkjnsT7wdU3zdFA`
  }

  get_static_map_blob = (lat: number, lng: number, zoom = 13, width = 300, height = 200) => {
    const url = this.get_static_map_url(lat, lng, zoom, width, height)
    return requests.get(url, {responseType: 'blob'}).then(res=> new Blob([res.data], {type: 'image/png'}))
    // return requests.get(url, {responseType: 'blob'}).then(res=>Buffer.from(res.data, 'binary'))
  }

}

export default new Map()
