import { Footmark } from "./footmark";

export enum TicketType {
  train = "train",
  airplane = "airplane",
  movie = "movie"
}

export enum TrainCompany {
  china_railway = "china_railay"
}

export enum AirplaneCompany {
  csair = "csair",
  ceair = "ceair"
}

export interface TicketTypeWithData {
  [TicketType.train]: TrainData;
  [TicketType.airplane]: AirplaneData;
  [TicketType.movie]: MovieData;
}

export interface TrainData {
  train_no: string;
  train_company: TrainCompany;
  from_station: string;
  to_station: string;
  departure_time: string;
  arrival_time: string;
  seat?: string;
  seat_type?: string;
  price: number;
}

export interface AirplaneData {
  flight_no: string;
  airplane_company: AirplaneCompany;
  from_station: string;
  from_iata: string;
  to_station: string;
  to_iata: string;
  departure_time: string;
  arrival_time?: string;
  seat?: string;
  seat_type?: string;
  price: number;
}

export interface MovieData {
  title: string;
  watch_time: string;
  cinema: string;
  seat: string;
  imdb_id?: string;
}

export interface Ticket<T extends TicketType> {
  id: string;
  ticket_type: T;
  picture: string;
  datetime_at: string;
  ticket_data: TicketTypeWithData[T];
}

export interface TicketResponseBase<T extends TicketType> extends Ticket<T> {
  footmarks: Footmark[]
}

export interface TicketPayload<T extends TicketType = any> extends Omit<Ticket<T>, "id"> {
  footmarks?: string[];
}

export type TicketData = TrainData | AirplaneData | MovieData;
export type TicketResponse =
  TicketResponseBase<TicketType.train> |
  TicketResponseBase<TicketType.airplane> |
  TicketResponseBase<TicketType.movie>

export interface Train12306 {
  date: string;
  from_station: string;
  station_train_code: string;
  to_station: string;
  total_num: string;
  train_no: string;
}

export interface Train12306Detail {
  arrive_day_str: string;
  station_name: string;
  train_class_name: string;
  is_start: string;
  service_type: string;
  end_station_name: string;
  arrive_time: string;
  start_station_name: string;
  station_train_code: string;
  arrive_day_diff: string;
  start_time: string;
  station_no: string;
  wz_num: string;
  running_time: string;
}

