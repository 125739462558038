import { Button, Empty } from "antd";
import { useNavigate } from "react-router";

import Header from "components/Layout/Header";
import { FootmarkListSkeleton } from "components/Skeleton";
import FootmarkCard from "components/Footmark/FootmarkCard";
import { useFootmarks } from "hooks/useFootmark";

const HomeFootmark = () => {

  const navigate = useNavigate()

  const footmarks = useFootmarks()

  return (
    <div className="flex flex-col">

      <Header />

      {footmarks.isLoading && <FootmarkListSkeleton className="px-8" />}

      {footmarks.data?.length!==0 && !footmarks.isLoading && <div className="px-4">
        <div className="text-center pt-4">
          <Button type="dashed" block onClick={() => navigate("/footmark/new")}>Create</Button>
        </div>
        {footmarks.data?.map(item => {
          const mapImage = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+555555(${item.location.split(",")[1]},${item.location.split(",")[0]})/${item.location.split(",")[1]},${item.location.split(",")[0]},10,0/300x200?before_layer=admin-0-boundary&access_token=pk.eyJ1Ijoid2FuZ2NvZGUiLCJhIjoiY2tpbWx1ZHFzMGxvczJ6b2FwbHg4MG54biJ9.Lhmn_bHKkjnsT7wdU3zdFA`
          return (
            <div className="mt-4" key={item.id} onClick={() => navigate(`/footmark/detail/${item.id}`)}>
              <FootmarkCard
                // image={item.images?.length===0?mapImage:item.images[0]}
                image={mapImage}
                name={item.name}
                time={item.time}
                country={item.nation}
                location={item.location} />
            </div>
          )
        })}
        <div className="text-center px-4 py-6 text-sm text-gray-400">--- The End ---</div>
      </div>}

      {footmarks.data?.length === 0 && (
        <Empty
          className="py-40"
          image={<img className="inline-block" src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="" />}
          imageStyle={{height: 80}}
          description={
            <div>
              <p>You have no footmark yet.</p>
              <p>Click the button below to add one.</p>
            </div>
          }
          >
          <Button type="primary" onClick={() => navigate("/footmark/new")}>Add a footmark</Button>
        </Empty>

      )}
    </div>
  )

}

export default HomeFootmark;
