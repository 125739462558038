import moment from 'moment';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import { Form, Button, message } from 'antd';

import { Input } from 'antd-mobile';

import Location from 'components/Form/Location';

import { FootmarkCreate as FootmarkCreateType } from 'types/footmark';

import FootmarkService from 'services/footmark';

import { MapGeocodeReversed } from 'types/map';

import QCloudUpload from 'components/Form/QCloudUpload';
import UINavigationBar from 'components/UIKit/UINavigationBar';
import { List } from 'antd-mobile';
import { Controller, useForm } from 'react-hook-form';

interface FormFieldType {
  location: MapGeocodeReversed
  time: string;
  images: string[];
}

const FootmarkCreate = () => {

  const navigate = useNavigate()

  const { control, getValues } = useForm<FormFieldType>()

  const footmarkCreateMutation = useMutation(
    (footmark: FootmarkCreateType) => FootmarkService.create(footmark),
    {
      onSuccess: () => {
        message.success("打卡成功！")
        navigate(-1)
      }
    }
  )

  const handleOnFinished = async () => {
    const values = getValues()
    // const mapThumb = await MapService.get_static_map_buffer(
    //   value.location.location.lat,
    //   value.location.location.lng,
    // )

    // const mapThumb = MapService.get_static_map_url(
    //   value.location.location.lat,
    //   value.location.location.lng,
    // )
    // const blob = await MapService.get_static_map_blob(
    //   value.location.location.lat,
    //   value.location.location.lng
    // )
    // const file = new File(blob, 'map.png', { type: 'image/png' })

    footmarkCreateMutation.mutate({
      name: values.location.title||values.location.address,
      address: values.location.address,
      nation: values.location.components.nation,
      location: `${values.location.location.lat},${values.location.location.lng}`,
      time: values.time,
      images: values.images,
      is_show: true
    })

  }

  return (

    <div className="min-h-full bg-gray-50">
      <UINavigationBar backItem title="New Footmark" hideScroll extra={<div onClick={handleOnFinished}>保存</div>} />

      <div className="p-4">
        <form>

          <div className="rounded-lg overflow-hidden shadow-md mb-3">
            <List style={{ '--prefix-width': '6em' }} className="border-0">
              <List.Item>
                <Controller
                  name="time"
                  control={control}
                  defaultValue={moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)}
                  render={({ field }) => <Input type="datetime-local" {...field} />} />
              </List.Item>
              <List.Item>
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => <Location onChange={field.onChange} />} />
              </List.Item>
            </List>
          </div>

          <div className="rounded-lg overflow-hidden shadow-md mb-3 bg-white">
            <div className='m-3'>
              <Controller
                name="images"
                control={control}
                render={({ field }) => <QCloudUpload onChange={field.onChange} />} />
            </div>
          </div>

        </form>
      </div>
    </div>

  )
}

export default FootmarkCreate;
