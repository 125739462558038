import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { Input, List, Toast, ImageUploader, Picker } from "antd-mobile"
import { useNavigate } from "react-router"
import { useMutation, useQuery } from "react-query"
import { PickerValue } from "antd-mobile/es/components/picker"

import { BiCloudUpload } from "react-icons/bi"
import { S3Service, TicketService } from "services"
import { TicketType, TrainData } from "types/ticket"
import FootmarkSelect from "components/Form/FootmarkSelect"

import { Controller, useFormContext } from 'react-hook-form'

interface TrainFormFields {
  datetime_at: string;
  picture: { url: string }[];
  footmarks: string[];
  ticket_data: {
    train_no: string;
    from_station: string;
    departure_time: string;
    to_station: string;
    arrival_time: string;
    seat: string;
    seat_type: string;
    price: number;
  }
}

interface TrainTicketFormProps {
  datetime?: string;
  footmarks?: string[];
  ticketData?: TrainData;
}

const TrainTicketForm: React.FC<TrainTicketFormProps> = ({ datetime, footmarks = [], ticketData }) => {

  const navigate = useNavigate()

  const { getValues, control, setValue, formState: { errors } } = useFormContext<TrainFormFields>()

  const [trainStatinCode, setTrainStatinCode] = useState("")

  const train = useQuery(["12306", trainStatinCode], () => TicketService.search12306TrainDetail(trainStatinCode), {
    enabled: !!trainStatinCode
  })

  const [pickerVisible, setPickerVisible] = useState(false)

  const trainPickerData = useMemo(() => {
    if (!train.data) return []
    const fromStation = train.data.map(train =>
      ({ label: train.station_name, value: `${train.station_name},${train.start_time}` })
    )
    const toStation = train.data.map(train =>
      ({ label: train.station_name, value: `${train.station_name},${train.arrive_time}` })
    )
    setPickerVisible(true)
    return [fromStation, toStation]
  }, [train.data])

  const mutation = useMutation(TicketService.createTrainTicket, {
    onSuccess: () => {
      Toast.show({
        icon: "success",
        content: "添加成功",
        afterClose: () => navigate(-2)
      })
    }
  })

  useEffect(() => {
    if (train.isLoading || mutation.isLoading) {
      Toast.show({
        icon: 'loading',
        content: '加载中…',
        duration: 0,
        maskClickable: false
      })
    } else {
      Toast.clear()
    }
  }, [train.isLoading, mutation.isLoading])

  useEffect(() => {
    console.log(errors)
  }, [errors])


  const handleOnSearch = () => {

    const [train_no, datetime_at] = getValues(["ticket_data.train_no", "datetime_at"])

    if (!train_no) {
      return Toast.show("请输入车次")
    }

    if (!datetime_at) {
      return Toast.show("请输入日期")
    }

    setTrainStatinCode(train_no)
  }

  const handleOnSearchConfirm = (value: PickerValue[]) => {
    const [start, end] = [value[0]?.split(","), value[1]?.split(",")]
    const datetime_at = getValues("datetime_at")
    start && setValue("ticket_data.from_station", start[0])
    start && setValue("ticket_data.departure_time", moment(`${datetime_at} ${start[1]}`).format(moment.HTML5_FMT.DATETIME_LOCAL))
    end && setValue("ticket_data.to_station", end[0])
    end && setValue("ticket_data.arrival_time", moment(`${datetime_at} ${end[1]}`).format(moment.HTML5_FMT.DATETIME_LOCAL))
    setPickerVisible(false)
  }

  // const handleOnDateChange = (val: string) => {
  //   form.setFieldsValue({
  //     departure_time: moment(val).format(moment.HTML5_FMT.DATETIME_LOCAL),
  //     arrival_time: moment(val).format(moment.HTML5_FMT.DATETIME_LOCAL),
  //   })
  // }

  return (
    <div>

      <div className="rounded-lg overflow-hidden shadow-md mb-3">
        <input type="hidden" name="ticket_type" value={TicketType.train} />
        <List style={{ '--prefix-width': '6em' }} className="border-0">
          <List.Item prefix='列车班次' extra={<div onClick={handleOnSearch}>搜索</div>}>
            <Controller
              name="ticket_data.train_no"
              control={control}
              defaultValue={ticketData?.train_no}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} />} />
            {errors.ticket_data?.train_no && Toast.show("列车班次必填！")}
          </List.Item>
          <List.Item prefix='日期'>
            <Controller
              name="datetime_at"
              control={control}
              defaultValue={datetime}
              render={({ field }) => <Input {...field} type="date" />} />
          </List.Item>
        </List>
      </div>

      <div className="rounded-lg overflow-hidden shadow-md mb-3">
        <List style={{ '--prefix-width': '6em' }} className="border-0">
          <List.Item prefix="上传图片" extra={<BiCloudUpload className="text-2xl block" />} />
        </List>
        <div className="p-4 bg-white border-t rounded-md overflow-hidden">
          <Controller
            name="picture"
            control={control}
            render={({ field }) => (
              <ImageUploader
                value={field.value}
                onChange={field.onChange}
                upload={S3Service.qcloud_upload}
                multiple={false}
                maxCount={1}
                style={{ "--cell-size": "160px" }}
              />
            )} />
        </div>
      </div>

      <div className="rounded-lg overflow-hidden shadow-md mb-3">
        <List style={{ '--prefix-width': '6em' }} className="border-0" >
          <List.Item prefix='出发地'>
            <Controller
              name="ticket_data.from_station"
              control={control}
              defaultValue={ticketData?.from_station}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} />} />
            {errors.ticket_data?.from_station && Toast.show("出发地必填！")}
          </List.Item>
          <List.Item prefix='出发时间'>
            <Controller
              name="ticket_data.departure_time"
              control={control}
              defaultValue={ticketData?.departure_time}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} type="datetime-local" />} />
            {errors.ticket_data?.departure_time && Toast.show("出发时间必填！")}
          </List.Item>
          <List.Item prefix='目的地'>
            <Controller
              name="ticket_data.to_station"
              control={control}
              defaultValue={ticketData?.to_station}
              rules={{ required: true }}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} />} />
            {errors.ticket_data?.to_station && Toast.show("目的地必填！")}
          </List.Item>
          <List.Item prefix='抵达时间'>
            <Controller
              name="ticket_data.arrival_time"
              control={control}
              defaultValue={ticketData?.arrival_time}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} type="datetime-local" clearable />} />
          </List.Item>
          <List.Item prefix='座位号'>
            <Controller
              name="ticket_data.seat"
              control={control}
              defaultValue={ticketData?.seat}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} />} />
          </List.Item>
          <List.Item prefix='座位类型'>
            <Controller
              name="ticket_data.seat_type"
              control={control}
              defaultValue={ticketData?.seat_type}
              render={({ field }) => <Input {...field} style={{ "--text-align": "right" }} />} />
          </List.Item>
          <List.Item prefix='票价'>
            <Controller
              name="ticket_data.price"
              control={control}
              defaultValue={ticketData?.price}
              render={({ field }) => <Input {...field} value={field.value?.toString()} style={{ "--text-align": "right" }} type="number" />} />
          </List.Item>
        </List>
      </div>

      <div className="mb-3">
        <div className="px-2 py-1 text-gray-500">关联足迹</div>
        <div className="rounded-lg overflow-hidden shadow-md">
          <Controller
            name="footmarks"
            control={control}
            defaultValue={footmarks}
            render={({ field }) => <FootmarkSelect value={field.value} onChange={field.onChange} />} />
        </div>
      </div>

      <Picker
        columns={trainPickerData}
        visible={pickerVisible}
        onCancel={() => setPickerVisible(false)}
        onConfirm={handleOnSearchConfirm}
      />

    </div>
  )
}

export default TrainTicketForm;
