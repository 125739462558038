import { Input, List, Image } from "antd-mobile"
// import { useNavigate } from "react-router"

import UINavigationBar from "components/UIKit/UINavigationBar"

import { BiCloudUpload } from "react-icons/bi"


const TicketNewMuseum: React.FC = () => {

  // const navigate = useNavigate()

  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar backItem title="New Ticket" hideScroll extra={"保存"} />

      <div className="p-4">
        <div className="grid gap-y-4">

          <div className="rounded-lg overflow-hidden shadow-md">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix='影片'>
                <Input />
              </List.Item>
              <List.Item prefix='观影时间'>
                <Input type="datetime-local" />
              </List.Item>
              <List.Item prefix='影院'>
                <Input />
              </List.Item>
              <List.Item prefix='座位'>
                <Input />
              </List.Item>
              <List.Item prefix='放映厅'>
                <Input />
              </List.Item>
            </List>
          </div>

          <div className="rounded-lg overflow-hidden shadow-md">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix="上传图片" extra={<BiCloudUpload className="text-2xl block" />} />
            </List>
            <div className="p-4 bg-white border-t">
              <Image className="rounded-md overflow-hidden" src="https://travel-1251006032.cos.ap-shanghai.myqcloud.com/7052a903-d67c-47bf-aa7c-60d965b8a046/1637216841_src%3Dhttp___photo.tuchong.com_1365359_f_15317651.jpg%26refer%3Dhttp___photo.tuchong.jpg" />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default TicketNewMuseum;
