import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import { AuthService } from 'services'

const Register: React.FC = () => {

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(AuthService.register, {
    onSuccess: async (_, params) => {
      const result = await AuthService.login({username: params.email, password: params.password})
      if(result){
        AuthService.loginWithToken(result.data.access_token)
        navigate('/', {replace: true})
      }else{
        navigate('/login', {replace: true})
      }
    },
  });

  return (
    <div>

      <div className="text-center pb-8">
        <div className="px-20 py-10 filter drop-shadow-xl"><img className="mx-auto" src={`${process.env.PUBLIC_URL}/logo.png`} alt="" /></div>
        <div className="text-2xl mb-2">Join to TravelMan</div>
        <div className="text-xs text-gray-400">Record every trip you make</div>
      </div>


      <div className="px-8">
        <Form onFinish={mutate}>
          <Form.Item name="email" rules={[{required: true, message: "Email is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" rules={[{required: true, message: "Password is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button className="rounded-lg h-12 shadow-lg bg-primary-1000 border-primary-900" htmlType="submit" loading={isLoading} size="large" block type="primary">Register</Button>
          </Form.Item>
        </Form>
      </div>

      <div className="py-8 text-center text-gray-400 fixed bottom-0 w-full">
        Already have account? <span onClick={() => navigate("/login", {replace: true})} className="text-sencondary-1000">Login!</span>
      </div>

    </div>
  )

}

export default Register;
