import { useState } from "react";
import { Toast, Image, ActionSheet, Dialog } from "antd-mobile";
import { RiMoreFill, RiTrainFill } from "react-icons/ri";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router"
import { TicketService } from "services";
import { TicketType } from "types/ticket";

import Map from "components/Leaflet";
import Square from "components/Square";
import UINavigationBar from "components/UIKit/UINavigationBar";
import TrainRouting from "components/Leaflet/extends/routing/train";

import "leaflet/dist/leaflet.css";


const TicketDetail: React.FC = () => {

  const navigate = useNavigate()
  const { ticketId } = useParams();

  const [ moreVisible, setMoreVisible ] = useState(false);

  const ticket = useQuery(
    ["ticket", "detail", ticketId],
    () => TicketService.get(ticketId!), {
      enabled: !!ticketId,
    }
  )

  const deleteMutation = useMutation(TicketService.delete, {
    onSuccess: () => {
      Toast.show({
        icon: "success",
        content: "删除成功",
        afterClose: () => navigate(-1)
      })
    }
  })

  const handleOnDeleteClick = () => {
    return Dialog.confirm({
      content: '是否删除',
      onConfirm: () => deleteMutation.mutate(ticketId!),
    })
  }

  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar
        backItem
        hideScroll
        title={moment(ticket.data?.datetime_at).format("YYYY-MM-DD")}
        extra={<RiMoreFill onClick={() => setMoreVisible(true)} className="text-xl" />}
      />

      <div className="p-4">
        {ticket.data?.ticket_type===TicketType.train && <div className="mb-4">
          <div className="bg-gradient-to-b from-blue-500 to-blue-400 text-white px-4 py-5">
            <div className="flex mb-2 justify-between">
              <div>{moment(ticket.data.datetime_at).format("YYYY-MM-DD")}</div>
              <div>{ticket.data.ticket_data.train_no}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-xl text-left">
                <div>{moment(ticket.data.ticket_data.departure_time).format("HH:mm")}</div>
                <div>{ticket.data.ticket_data.from_station}</div>
              </div>
              <div>
                <RiTrainFill className="text-4xl" />
              </div>
              <div className="text-xl text-right">
                <div>{moment(ticket.data.ticket_data.arrival_time).format("HH:mm")}</div>
                <div>{ticket.data.ticket_data.to_station}</div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 py-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-3 text-gray-500 items-center">
                <MdAirlineSeatReclineExtra className="text-2xl" />
                <div className="text-base">座位</div>
              </div>
              <div className="gap-3 flex">
                <div>{ticket.data.ticket_data.seat}</div>
                <div>{ticket.data.ticket_data.seat_type}</div>
              </div>
            </div>
          </div>
        </div>}

        {/* {ticket.data?.data.ticket_type===TicketType.train && <div className="bg-white rounded-lg shadow-md mb-4 p-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-3 text-gray-500 items-center">
              <MdAirlineSeatReclineExtra className="text-2xl" />
              <div className="text-base">座位</div>
            </div>
            <div>{ticket.data.data.ticket_data.seat} {ticket.data.data.ticket_data.seat_type}</div>
          </div>
        </div>} */}

        <div className="bg-white shadow-md p-4 rounded-lg border-dashed border-green-400 border-4 mb-4">
          {/* <div className="flex overflow-x-auto gap-4 h-56"> */}
            {ticket.data && <Image className="flex-none rounded-md overflow-hidden" src={ticket.data.picture} />}
          {/* </div> */}
        </div>

        {ticket.data?.ticket_type===TicketType.train && ticket.data.footmarks.length!==0 &&
        <div className="bg-white overflow-hidden rounded-lg mb-4 shadow-md">
          <Square proportion={{width: 3, height: 2}} >
            <Map
              dragging={false} zoomControl={false}
              style={{width: "100%", height: "100%"}}
            >
              <TrainRouting coordinates={ticket.data.footmarks.map(footmark=>footmark.location.split(",").map(item => parseFloat(item)) as [number, number])} />
            </Map>
          </Square>
        </div>}

      </div>

      <ActionSheet
        visible={moreVisible}
        actions={[
          { text: '修改', key: 'edit', onClick: () => navigate(`/ticket/edit/${ticketId}`) },
          { text: '删除', key: 'delete', danger: true, onClick: handleOnDeleteClick },
        ]}
        cancelText='取消'
        onClose={() => setMoreVisible(false)}
      />

    </div>
  )
}

export default TicketDetail
