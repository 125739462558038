import L from 'leaflet';
import { useMapEvents } from "react-leaflet";
// import { useEffect } from 'react';

interface LeafletEventProps extends L.LeafletEventHandlerFnMap {

}

const LeafletEvent: React.FC<LeafletEventProps> = (props) => {

  useMapEvents({
    ...props
  })

  return null

}

export default LeafletEvent;
