import { Button, CheckList, Ellipsis, List, Popup } from "antd-mobile";
import moment from "moment";
import React, { useState } from "react";
import { BsPlusSquare } from "react-icons/bs";

import { FiTrash } from 'react-icons/fi';
import { useQuery } from "react-query";
import { FootmarkService } from "services";

interface FootmarkSelectProps {
  max?: number;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const FootmarkSelect: React.FC<FootmarkSelectProps> = ({ max = 2, value, onChange }) => {

  const [ visible, setVisible ] = useState(false);
  // const [ selectedId, setSelectedId ] = useState<string[]>([]);

  const footmarks = useQuery(["footmarks"], FootmarkService.getAll)

  const handleOnRemove = (id: string) => {
    value && onChange?.(value.filter(i => i !== id))
  }

  return (
    <div>
      <List className="border-0" >
        {footmarks.data?.map(footmark => {
          if(!value?.includes(footmark.id)) return null
          return (
            <List.Item
              key={footmark.id}
              className="text-sm"
              prefix={<div className="text-center text-gray-500">
                <div>{moment(footmark.time).format("YYYY-MM-DD")}</div>
                {/* <div>{moment(footmark.time).format("HH:mm")}</div> */}
              </div>}
              extra={<FiTrash onClick={() => handleOnRemove(footmark.id)} />}>
                <Ellipsis direction='end' content={footmark.name} />
            </List.Item>
          )
        })}
        <List.Item className="text-center">
          <div onClick={() => setVisible(true)} className="flex space-x-2 justify-center items-center">
            <BsPlusSquare />
            <label htmlFor="test">选择</label>
          </div>
        </List.Item>
      </List>
      <Popup
        position="bottom"
        visible={visible}
        destroyOnClose
        onMaskClick={() => setVisible(false)}
        bodyClassName="h-2/3"
      >
        <CheckList multiple onChange={onChange} value={value} >
          {/* <Button block>新增</Button> */}
          {footmarks.data?.map(footmark => {
            return (
              <CheckList.Item
                className="text-sm"
                // readOnly={!value?.includes(footmark.id) && value?.length>max}
                key={footmark.id}
                prefix={moment(footmark.time).format("YYYY-MM-DD")}
                value={footmark.id}>
                  <Ellipsis direction='end' content={footmark.name} />
              </CheckList.Item>
            )
          })}
        </CheckList>
      </Popup>
    </div>
  )

}

export default FootmarkSelect;
