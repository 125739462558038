import React, { useMemo, useRef } from 'react';
// import { Upload, Spin } from 'antd';
import { ImageUploader } from 'antd-mobile';
// import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { S3Service } from 'services';
// import Square from 'components/Square';
// import axios from 'axios';
// import { UploadFile } from 'antd/lib/upload/interface';
// import { AiOutlinePlus } from 'react-icons/ai';
// import { useAsyncFn } from 'react-use';

// interface QCloudReponse{
//   url: string;
// }

interface QCloudUploadProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  max?: number;
}

const QCloudUpload: React.FC<QCloudUploadProps> = (props) => {

  const wrap = useRef<HTMLDivElement>(null)

  // const [files, setFiles] = useState<UploadFile<QCloudReponse>[]>([])

  // const [{loading}, getPresignedUrl] = useAsyncFn((file: RcFile) => S3Service.qcloud_presigned(file.name).then(res=>res.data.url))

  // const handleOnChange = (info: UploadChangeParam<UploadFile<QCloudReponse>>) => {
  //   setFiles(info.fileList)
  //   if(info.file.status==="done") {
  //     props.onChange?.(info.fileList.map(file => file.response.url))
  //   }
  //   if(info.file.status==="uploading") {}
  //   if(info.file.status==="removed") {}
  //   if(info.file.status==="error") {}
  // }

  return (
    <div ref={wrap}>
      <ImageUploader
        style={{"--cell-size": `${(wrap.current?.offsetWidth || 240) / 3}px`}}
        value={props.value?.map(item=>({url: item}))}
        onChange={filelist => props.onChange?.(filelist.map(item=>item.url))}
        upload={S3Service.qcloud_upload}
        multiple
        // maxCount={3}
        // showUpload={fileList.length < maxCount}
        // onCountExceed={exceed => {
        //   Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
        // }}
      />
    </div>
    // <div className="grid grid-cols-3 gap-2">
    //   {files.map(file => {
    //     return (
    //       <Spin key={file.uid} spinning={file.percent!==100}>
    //         <Square>
    //           {file.percent===100 && <Image width="100%" height="100%" src={file.response?.url||""} fit='cover'/>}
    //         </Square>
    //       </Spin>
    //     )
    //   })}
    //   <Square>
    //     <Upload
    //       action={getPresignedUrl}
    //       onChange={handleOnChange}
    //       method="PUT"
    //       showUploadList={false}
    //       className="flex w-full h-full items-center justify-center bg-gray-50"
    //       customRequest={(option) => {
    //         axios({
    //           url: option.action,
    //           method: option.method,
    //           data: option.file,
    //           headers: {
    //             ...option.headers,
    //             'Content-Type': 'multipart/form-data'
    //           },
    //           onUploadProgress: (event: ProgressEvent) => option.onProgress?.({...event, percent: Math.round(event.loaded / event.total * 100)})
    //         })
    //           .then((res) => {
    //             option.onSuccess?.({
    //               response: res,
    //               url: option.action.split("?")[0]
    //             }, res.request)
    //           })
    //           .catch((err) => option.onError?.(new Error(err.message)))
    //       }}
    //     >
    //       <Spin spinning={loading}><AiOutlinePlus className="w-full h-full" fontSize="26" /></Spin>
    //     </Upload>
    //   </Square>
    // </div>
  )

}

export default QCloudUpload;
