import { AxiosResponse } from 'axios';

import requests from 'lib/request';

import { Footmark, FootmarkCreate, FootmarkUpdate } from 'types/footmark'

class FootmarkService {

  getAll = async () => {
    return requests.get<Footmark[]>("/footmark").then(res => res.data);
  }


  get = async (footmarkId: string) => {
    return requests.get<Footmark>(`/footmark/${footmarkId}`).then(res=>res.data)
  }


  delete = async (footmarkId: string) => {
    return requests.delete<boolean>(`/footmark/${footmarkId}`)
  }


  update = async (footmarkId: string, footmark: FootmarkUpdate) => {
    return requests.put<FootmarkUpdate, AxiosResponse<Footmark>>(`/footmark/${footmarkId}`, footmark)
  }


  create = async (footmark: FootmarkCreate) => {
    return requests.post<FootmarkUpdate, AxiosResponse<Footmark>>("/footmark", footmark)
  }

}

export default new FootmarkService();


