import classNames from "classnames";
import { useMemo, useRef } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useWindowScroll } from "react-use";

interface UINavigationBarProps {
  title: React.ReactNode;
  backItem?: boolean|React.ReactNode;
  extra?: React.ReactNode;
  hideScroll?: boolean;
}

const UINavigationBar: React.FC<UINavigationBarProps> = (props) => {

  const nav = useNavigate()

  const largeTitleRef = useRef<HTMLDivElement>(null)
  const { y } = useWindowScroll()

  const IsScrollOver = useMemo(() => {
    return props.hideScroll || y > (largeTitleRef?.current?.clientHeight || 40)
  }, [y, largeTitleRef, props.hideScroll])

  return (
    <div>

      <div className="relative w-full h-14">
        <div className="fixed z-10 w-full h-14 top-0">

          <div className="text-center h-14 fixed top-0 w-full ">
            <div className={classNames(
              "w-full h-full bg-white",
              {"bg-opacity-60 backdrop-filter backdrop-blur-lg border-b border-gray-200": IsScrollOver},
            )}>
              <div className={classNames(
                "transition-opacity duration-200 ease-in-out flex items-center justify-between w-full h-full px-2",
                {"opacity-0": !IsScrollOver}
              )}>
                <div className="text-base w-28 text-left" >
                  {props.backItem===true?
                  <div className="flex items-center" onClick={() => nav(-1)} >
                    <RiArrowLeftSLine className="text-3xl block" />
                    <div>返回</div>
                  </div>:
                  props.backItem}
                </div>
                <div className="text-base font-medium">{props.title}</div>
                <div className="text-base w-28 text-right">{props.extra}</div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {!props.hideScroll && <div className="font-bold text-3xl pb-3 px-4">
        <div ref={largeTitleRef} className={classNames({"opacity-0": IsScrollOver}, "flex justify-between items-end")}>
          <div>
            {props.title}
          </div>
          <div className="inline-block text-blue-500 text-xl">
            {props.extra}
          </div>
        </div>
      </div>}

    </div>
  )
}

export default UINavigationBar;
