import { useNavigate, useParams } from "react-router";
import BottomBar, { HomeTabBar } from "components/Layout/BottomBar";
import HomeFootmark from "./footmark";
import HomeTicket from "./ticket";
import HomeUser from "./user";

const Home = () => {

  const navigate = useNavigate()
  const { tab } = useParams()

  const handleTabChange = (tab: HomeTabBar) => {
    navigate(`/home/${tab}`, {replace: true})
  }

  return (
    <div className="flex flex-col">
      <div style={{display:tab===HomeTabBar.Footmark?"block":"none"}}><HomeFootmark /></div>
      <div style={{display:tab===HomeTabBar.Ticket?"block":"none"}}><HomeTicket /></div>
      <div style={{display:tab===HomeTabBar.User?"block":"none"}}><HomeUser /></div>
      <BottomBar active={tab as HomeTabBar} onChange={handleTabChange} />
    </div>
  )

}

export default Home;
