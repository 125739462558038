import axios from 'axios';
import requests from 'lib/request';

interface OraclePresignedResponse {
  url: string;
  expires: string;
}

interface AWSPresignedResponse {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  }
}

interface QCloudPresignedResponse {
  url: string;
}

class S3Service {

  oracle_presigned = async (fileName: string) => {
    return requests.get<OraclePresignedResponse>(`/s3/oracle/presigned/${fileName}`)
  }

  aws_presigned = async (fileName: string) => {
    return requests.get<AWSPresignedResponse>(`/s3/aws/presigned/${fileName}`)
  }

  qcloud_presigned = async (fileName: string) => {
    return requests.get<QCloudPresignedResponse>(`/s3/qcloud/presigned/${fileName}`)
  }

  qcloud_upload = async (file: File, filename?: string) => {
    const url = await this.qcloud_presigned(filename||file.name).then(res=>res.data.url);
    return axios({
      url,
      method: "PUT",
      data: file,
      headers: {'Content-Type': 'multipart/form-data'}
    })
      .then(() => {
        return {url: url.split("?")[0]}
      })
  }

}

export default new S3Service();


