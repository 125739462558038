import { Input, List, Toast, ImageUploader } from "antd-mobile"

import UINavigationBar from "components/UIKit/UINavigationBar"

import { BiCloudUpload } from "react-icons/bi"
import { S3Service } from "services"

import { useForm, Controller } from 'react-hook-form'
import FootmarkSelect from "components/Form/FootmarkSelect"

interface ThemeParkField {

  themepark: string;
  price: string;

  date: string;
  picture: {url: string}[];
  footmarks: string[];
}


const TicketNewThemePark: React.FC = () => {

  const { control } = useForm<ThemeParkField>()


  const handleOnFinished = ({picture, price, ...values}: ThemeParkField) => {

    // if(!values.train_no) {
    //   return Toast.show("列车班次必填！")
    // }

    // if(!values.from_station) {
    //   return Toast.show("出发地必填！")
    // }

    // if(!values.departure_time) {
    //   return Toast.show("出发时间必填！")
    // }

    // if(!values.to_station) {
    //   return Toast.show("目的地必填！")
    // }

    // mutation.mutate({
    //   picture: picture?.[0].url,
    //   datetime_at: moment(values.departure_time).format("YYYY-MM-DD HH:mm:ss"),
    //   ticket_data: {
    //     ...values,
    //     price: parseFloat(price),
    //     train_company: TrainCompany.china_railway,
    //   }
    // })
  }

  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar backItem title="New Ticket" hideScroll extra={<div>保存</div>} />

      <div className="p-4">

        <form>

          <div className="rounded-lg overflow-hidden shadow-md mb-3">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix='主题乐园' extra={<div>搜索</div>}>
                <Controller name="themepark" control={control} render={({field}) => <Input {...field} />} />
              </List.Item>
              <List.Item prefix='入园日'>
                <Controller name="date" control={control} render={({field}) => <Input {...field} type="date" />}  />
              </List.Item>
              <List.Item prefix='票价'>
                <Controller name="price" control={control} render={({field}) => <Input {...field} />}  />
              </List.Item>
            </List>
          </div>

          <div className="rounded-lg overflow-hidden shadow-md mb-3">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix="上传图片" extra={<BiCloudUpload className="text-2xl block" />} />
            </List>
            <div className="p-4 bg-white border-t rounded-md overflow-hidden">
            <Controller name="picture" control={control} render={({field}) =>
              <ImageUploader
                onChange={field.onChange}
                upload={S3Service.qcloud_upload}
                multiple={false}
                maxCount={3}
                style={{"--cell-size": "160px"}}
              />} />
            </div>
          </div>

          <div className="mb-3">
            <div className="px-2 py-1 text-gray-500">关联足迹</div>
            <div className="rounded-lg overflow-hidden shadow-md">
              <Controller
                name="footmarks"
                control={control}
                render={({ field }) => <FootmarkSelect value={field.value} onChange={field.onChange} />} />
            </div>
          </div>

        </form>

      </div>
    </div>
  )
}

export default TicketNewThemePark;
