import { Button, Form, Input, message } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import { AuthService } from 'services'

const PasswordChange: React.FC = () => {

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(AuthService.login, {
    onSuccess: res => {
      AuthService.loginWithToken(res.data.access_token)
      navigate('/', { replace: true })
    },
    onError: err => {
      message.error({content: err})
    }
  });

  return (
    <div>

      <div className="p-8">
        <div className="text-2xl mb-2 font-bold">Create new password</div>
        <div className="text-base text-gray-500">Your new password must be different from previous used passwords.</div>
      </div>


      <div className="px-8">
        <Form requiredMark={false} layout="vertical" onFinish={mutate}>
          <Form.Item label="Old Password" name="oldpassword" rules={[{required: true, message: "Username is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" type="password" placeholder="Old Password" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{required: true, message: "Username is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" type="password" placeholder="New Password" />
          </Form.Item>
          <Form.Item label="Confirm Password" name="confirmpassword" rules={[{required: true, message: "Password is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" type="password" placeholder="Confirm New Password" />
          </Form.Item>
          <Form.Item>
            <Button className="rounded-lg h-12 shadow-lg" htmlType="submit" loading={isLoading} size="large" block type="primary">Reset Password</Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  )

}

export default PasswordChange;
