import qs from 'querystring';
import requests from 'lib/request';
import { LoginPayload, LoginResponse, RegisterPayload, RegisterResponse } from 'types/auth';
import { AxiosResponse } from 'axios';

class AuthService {

  public login = async ({username, password}: LoginPayload) => {
    return requests.post<string, AxiosResponse<LoginResponse>>('/auth/login', qs.stringify({ username, password }))
  }

  public register = async ({email, password}: {email: string, password: string}) => {
    return requests.post<RegisterPayload, AxiosResponse<RegisterResponse>>('/auth/register', { email, password })
  }

  public verify = async (token: string) => {
    return requests.post<{token: string}, AxiosResponse<LoginResponse>>('/auth/verify', {token})
  }

  public refreshToken = async () => {
    return requests.post('/auth/refresh')
  }

  public loginWithToken = (token: string) => {
    localStorage.setItem('token', token);
  }

  public logout = () => {
    localStorage.removeItem('token');
  }

}

export default new AuthService();


