import { createContext } from "react"
import { TicketData, TicketType, TrainData } from "types/ticket"
import TrainTicketForm from "./ticket"

interface TicketFormProps {
  ticketType: TicketType;
  datetime?: string;
  footmarks?: string[];
  ticketData?: TicketData;
}

// const defaultTicketFormContext = {
//   onSubmit: () => {}
// }

// export const TicketFormContext = createContext(defaultTicketFormContext)

// const useTicketForm = () => {

// }

const TicketForm: React.FC<TicketFormProps> = ({ ticketType, datetime, footmarks, ticketData }) => {

  return (
    <form>
      {ticketType === TicketType.train && (
        <TrainTicketForm
          datetime={datetime}
          footmarks={footmarks}
          ticketData={ticketData as TrainData}
        />
      )}
    </form>
  )

}

export default TicketForm
