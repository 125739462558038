import { AxiosResponse } from 'axios';

import requests from 'lib/request';
import moment from 'moment';

import { TicketPayload, TicketResponse, TicketType, Train12306, Train12306Detail } from 'types/ticket';

class TicketService {

  getAll = async () => {
    return requests.get<TicketResponse[]>("/ticket").then(res => res.data);
  }

  get = async (ticketId: string) => {
    return requests.get<TicketResponse>(`/ticket/${ticketId}`).then(res => res.data)
  }

  delete = async (ticketId: string) => {
    return requests.delete<boolean>(`/ticket/${ticketId}`)
  }

  updateTicket = async <T extends TicketType>(ticketId: string, ticket: TicketPayload<T>) => {
    return requests.put<TicketPayload<T>, AxiosResponse<TicketResponse>>(`/ticket/${ticketId}`, ticket)
  }

  // createTicket = async <T extends TicketType>(ticket: TicketPayload<T>) => {
  //   return requests.post<TicketPayload<T>, AxiosResponse<TicketResponse>>("/ticket", ticket)
  // }

  createTrainTicket = async (ticket: Omit<TicketPayload<TicketType.train>, "ticket_type">) => {
    return requests.post<TicketPayload<TicketType.train>, AxiosResponse<TicketResponse>>("/ticket", { ...ticket, ticket_type: TicketType.train })
  }

  createAirplaneTicket = async (ticket: Omit<TicketPayload<TicketType.airplane>, "ticket_type">) => {
    return requests.post<TicketPayload<TicketType.airplane>, AxiosResponse<TicketResponse>>("/ticket", { ...ticket, ticket_type: TicketType.airplane })
  }

  createMovieTicket = async (ticket: Omit<TicketPayload<TicketType.airplane>, "ticket_type">) => {
    return requests.post<TicketPayload<TicketType.airplane>, AxiosResponse<TicketResponse>>("/ticket", { ...ticket, ticket_type: TicketType.airplane })
  }


  // 12306
  search12306Train = async (station_train_code: string) => {
    return requests.get<{ data: Train12306[] }>("/ticket/12306/search", { params: { station_train_code, date: moment().format("YYYYMMDD") } })
  }

  get12306TrainDetail = (train_no: string) => {
    return requests.get<{ data: { data: Train12306Detail[] } }>("/ticket/12306/detail", { params: { train_no, date: moment().format("YYYY-MM-DD") } })
  }

  search12306TrainDetail = async (station_train_code: string) => {
    const trainList = await this.search12306Train(station_train_code);
    const trainInfo = trainList.data.data.find(train => train.station_train_code === station_train_code)
    return trainInfo && this.get12306TrainDetail(trainInfo.train_no).then(res => res.data.data.data)
  }

}

export default new TicketService();
