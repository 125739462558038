import { useMutation, UseMutationOptions, useQuery } from "react-query"
import { TicketService } from "services"
import { TicketPayload, TicketType } from "types/ticket"

export const useTickets = () => {

  const tickets = useQuery(["ticket"], TicketService.getAll)

  return tickets

}

interface useTicketOptions {
  update: Omit<UseMutationOptions, 'mutationFn'>
}

export const useTicket = (ticketId: string, options?: useTicketOptions) => {

  const ticket = useQuery(["ticket", ticketId], () => TicketService.get(ticketId))

  const update = useMutation(
    (ticket: TicketPayload) => TicketService.updateTicket(ticketId, ticket)
  )

  return { ticket, update }

}
