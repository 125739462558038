import { Route, Routes } from "react-router"
import FootmarkCreate from "./create"
import TicketDetail from "./detail"

const FootmarkRoutes: React.FC = () => {

  return (
    <Routes>
      {/* <Route path="new/*" element={<TicketNew />} /> */}
      <Route path="new" element={<FootmarkCreate />} />
      <Route path="detail/:ticketId" element={<TicketDetail />} />
    </Routes>
  )
}

export default FootmarkRoutes
