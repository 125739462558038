
import React from 'react'
import {
  RiTicket2Line, RiTicket2Fill,
  // RiRoadMapLine, RiRoadMapFill,
  RiUser6Line, RiUser6Fill,
  RiHomeSmileLine, RiHomeSmileFill
} from 'react-icons/ri'
import classnames from 'classnames';

export enum HomeTabBar {
  Footmark = 'footmark',
  Map = "map",
  Ticket = "ticket",
  User = "user"
}

interface BottomBarProps {
  active?: HomeTabBar;
  onChange?: (active: HomeTabBar) => void;
}

const BottomBar: React.FC<BottomBarProps> = (props) => {

  // const [active, setActive] = useState<HomeTabBar>(HomeTabBar.Footmark)

  return (
    <div className="relative w-full h-16">
      <div className="fixed z-10 w-full h-16 bottom-0 text-gray-500 bg-white border-t border-gray-100 backdrop-filter backdrop-blur-sm bg-opacity-90">
        <div className="flex justify-around items-center h-full text-2xl w-full">
          <div onClick={() => props.onChange?.(HomeTabBar.Footmark)} className={classnames({"text-primary-1000": props?.active===HomeTabBar.Footmark})}>
            {props?.active===HomeTabBar.Footmark?<RiHomeSmileFill />:<RiHomeSmileLine />}
          </div>
          {/* <div onClick={() => props.onChange?.("map")} className={classnames({"text-primary-1000": props?.active==="map"})}>
            {props?.active==="map"?<RiRoadMapFill />:<RiRoadMapLine />}
          </div> */}
          <div onClick={() => props.onChange?.(HomeTabBar.Ticket)} className={classnames({"text-primary-1000": props?.active===HomeTabBar.Ticket})}>
            {props?.active===HomeTabBar.Ticket?<RiTicket2Fill />:<RiTicket2Line />}
          </div>
          <div onClick={() => props.onChange?.(HomeTabBar.User)} className={classnames({"text-primary-1000": props?.active===HomeTabBar.User})}>
            {props?.active===HomeTabBar.User?<RiUser6Fill />:<RiUser6Line />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomBar
