import { Avatar } from 'antd';
import Square from 'components/Square';
import { useFootmarks } from 'hooks/useFootmark';
import { useTickets } from 'hooks/useTicket';
import { useUser } from 'hooks/useUser';
import { IoTicket } from 'react-icons/io5';
import { RiArrowRightSLine, RiMailOpenLine, RiRoadMapLine, RiSettings3Line } from 'react-icons/ri';


const HomeUser = () => {

  const user = useUser()
  const tickets = useTickets()
  const footmarks = useFootmarks()

  return (
    <div className="min-h-full">
      <div className="px-4 my-6">

        <div className="flex items-center gap-8 mb-6">
          <div className="w-1/3 rounded-xl shadow-xl overflow-hidden">
            <Square>
              <Avatar shape="square" style={{height: "100%", width: "100%"}} src={user.data?.avatar} />
            </Square>
          </div>
          <div className="text-2xl font-semibold">{user.data?.email}</div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="shadow-xl p-5 rounded-xl flex items-center space-x-2">
            <IoTicket className="text-2xl" />
            <div className="text-xl font-bold">{tickets.data?.length}</div>
          </div>
          <div className="shadow-xl p-5 rounded-xl flex items-center space-x-2">
            <RiRoadMapLine className="text-2xl" />
            <div className="text-xl font-bold">{footmarks.data?.length}</div>
          </div>
        </div>

      </div>

      <div>
        <div className="px-4 flex text-xl items-center py-3 justify-between hover:bg-gray-50 focus:bg-gray-50">
          <div className="flex items-center gap-3">
            <RiSettings3Line className="text-gray-700" />
            <div className="font-semibold">Settings</div>
          </div>
          <RiArrowRightSLine className="text-gray-500" />
        </div>
        <div className="px-4 flex text-xl items-center py-3 justify-between hover:bg-gray-50 focus:bg-gray-50">
          <div className="flex items-center gap-3">
            <RiMailOpenLine className="text-gray-700" />
            <div className="font-semibold">Faq</div>
          </div>
          <RiArrowRightSLine className="text-gray-500" />
        </div>
      </div>


    </div>
  )

}
export default HomeUser
