import L, { LatLngTuple } from "leaflet"
import React, { useEffect } from "react"
import { useMap } from "react-leaflet"
// import { decode } from "@googlemaps/polyline-codec";
import { decode } from "@mapbox/polyline"
import { Coordinate, OSRM } from 'osrm-rest-client';

interface TrainRoutingProps {
  coordinates: Coordinate[]
}

const TrainRouting: React.FC<TrainRoutingProps> = (props) => {
  const map = useMap()

  useEffect(() => {
    // @ts-ignore
    const osrm = OSRM({ osrm: "https://signal.eu.org/osm", defaultProfile: "train" })
    osrm.route({
      steps: true,
      alternatives: false,
      overview: 'simplified',
      geometries: 'polyline',
      coordinates: props.coordinates.map(item => [item[1], item[0]]),
    }).then(res => {
      const list: LatLngTuple[][] = []
      res.routes.forEach(item => {
        item.legs?.forEach(leg => {
          leg.steps?.forEach(step => {
            step.geometry && list.push(decode(step.geometry as string))
          })
        })
      })
      const polyline = L.polyline(list)
      polyline.addTo(map)
      map.fitBounds(polyline.getBounds());
    })
  }, [map, props.coordinates])

  return null;
}

export default TrainRouting
