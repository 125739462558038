import requests from 'lib/request';
import { User } from 'types/user';

class UserService {

  getMyProfile() {
    return requests.get<User>('/user/me').then(res=>res.data);
  }

  updateProfile() {
    return requests.put<User>('/user/me');
  }

}

export default new UserService();


