import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import Home from './pages/home';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import PasswordChange from 'pages/auth/password/change';
import TicketRoute from 'pages/ticket';
import FootmarkRoutes from 'pages/footmark';

import 'antd/dist/antd.variable.min.css';
import './index.css'

ConfigProvider.config({
  theme: {
    primaryColor: "#2445CD",
    infoColor: "#543AF8",
    successColor: "#2AC769",
    processingColor: "#8A9DF0",
    errorColor: "#FB4E4E",
    warningColor: "#F6A609",
  },
});

const query = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.render(
  <QueryClientProvider client={query}>
    <ConfigProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/home/footmark" />} />
          <Route path="/home/:tab" element={<Home />}></Route>
          <Route path="/footmark/*" element={<FootmarkRoutes />} />
          <Route path="/ticket/*" element={<TicketRoute />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password/change" element={<PasswordChange />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  </QueryClientProvider>,
  document.getElementById('root')
);
