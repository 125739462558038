import React from 'react'
import styles from './index.module.scss';

interface SquareProps {
  skeleton?: boolean;
  proportion?: {
    width: number;
    height: number;
  }
}

const Square: React.FC<SquareProps> = ({ skeleton, proportion, children }) => {

  const paddingBottom = (proportion?.height||1) / (proportion?.width||1) * 100

  return (
    <div className={styles.square}>
      <div className={styles.placeholder} style={{paddingBottom: `${paddingBottom}%`}} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default Square
