import React, { useEffect, useMemo, useState } from 'react';
import gcord from 'gcoord'
import { Button, Input } from 'antd';
import { Map as LeafletMap } from 'leaflet';
import { useInfiniteQuery } from 'react-query';
import { InfiniteScroll, List } from 'antd-mobile';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { RiMapPinFill } from 'react-icons/ri';
import { PopupProps } from 'antd-mobile/es/components/popup';
import { FeatureGroup } from 'react-leaflet';

import { MapService } from 'services';
import { AddressComponent, MapLocation } from 'types/map';

import Map from 'components/Leaflet';
import LeafletEvent from 'components/Leaflet/extends/event';
import LeafletLocateControl from 'components/Leaflet/extends/locatecontrol';

import styles from './index.module.scss';
import { DEFAULT_PAGE_SIZE } from 'config/settings';
import { debounce } from 'lodash';


interface SelectLocationItem {
  title: string;
  address: string;
  location: MapLocation;
  components: AddressComponent;
  distance: number;
}

export interface SelectedParams extends SelectLocationItem {
  zoom: number;
}

interface SelectLocationProps extends PopupProps {
  location?: MapLocation;
  onSelected?: (params: SelectedParams) => void;
  onCancel?: () => void;
}

const CheckIcon = () => <div className="flex items-center"><RiCheckboxCircleFill className="text-green-600 text-xl" /></div>

const SelectLocation: React.FC<SelectLocationProps> = ({ onSelected, onCancel, ...props }) => {

  const [map, setMap] = useState<LeafletMap>()

  const [selected, setSelected] = useState<SelectLocationItem>()
  const [searchKey, setSearchKey] = useState("")

  const [location, setLocation] = useState<MapLocation>()


  useEffect(() => {
    setLocation(props.location)
  }, [props.location])

  const { data, isLoading, isFetching, fetchNextPage } = useInfiniteQuery(
    ["locations", location, searchKey],
    ({ pageParam }) => {
      if(location) {
        return MapService.search({ location: location, page: pageParam, keyword: searchKey }).then(res => res.data)
      }
    },
    {
      enabled: !!location,
      getNextPageParam: (lastPages) => lastPages?.nextPage,
      refetchOnWindowFocus: false
    }
  )

  const addressList = useMemo(() => data?.pages.flatMap(item => item?.data), [data])

  const handleOnViewPortChange: L.LeafletEventHandlerFn = () => {
    const center = map?.getCenter()
    center && setSelected(undefined)
    center && setLocation({
      lat: center.lat,
      lng: center.lng,
      type: gcord.CRSTypes.WGS84
    })
  }

  const handleOnSelectConfirm = () => {
    if(!map) return
    const zoom = map.getZoom()
    if(selected) {
      onSelected?.({...selected, zoom})
    } else if(addressList?.length!==0 && addressList?.[0]) {
      onSelected?.({...addressList[0], zoom})
    }
  }



  const handleOnSearchKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
  };

  // const handleOnLocated = () => {
  //   const {longitude, latitude} = geoLocation
  //   if(longitude && latitude) {
  //     mapbox?.flyTo({ center: [longitude, latitude], zoom: 17 })
  //     setViewPort({ latitude, longitude, zoom: 17 })
  //   }
  // }

  const debouncedEventHandler = useMemo(() => debounce(handleOnSearchKeyChange, 500), []);

  return (
    <div className="flex relative flex-col h-full">
      <div className="h-3/5">
        <div className={styles.topBar}>
          <Button type="link" onClick={onCancel}>取消</Button>
          <Button className="btn-primary" disabled={isLoading || isFetching} onClick={handleOnSelectConfirm}>确定</Button>
        </div>
        <Map
          zoom={15}
          center={props.location||{lat: 41.51027233873624, lng: 123.36732387542726}}
          whenCreated={setMap}
          style={{ width: "100%", height: "100%" }}
          zoomControl={false}
        >
          <FeatureGroup>
            <RiMapPinFill className={`${styles.pin} text-primary-1000`} />
            <LeafletLocateControl start={!props.location} position="bottomright" />
          </FeatureGroup>
          <LeafletEvent moveend={handleOnViewPortChange} />
        </Map>
      </div>

      <div className="h-2/5 relative overflow-scroll">
        <div>
          {/* <Input onChange={debouncedEventHandler} /> */}
          <Input className="h-10 border-transparent" onChange={debouncedEventHandler} />
        </div>
          <div className="w-full">
            {addressList?.length!==0 && <List>
              {addressList?.map((address, index) =>(
                <List.Item
                  arrow={false}
                  key={index}
                  className={styles.listItem}
                  extra={(selected ? selected===address : index===0 )&& <CheckIcon /> }
                  onClick={() => setSelected(address)} >
                  <p className="truncate ... mb-1">{address?.title}</p>
                  <p className="truncate ... text-sm text-gray-600">{address?.distance}m | {address?.address}</p>
                </List.Item>
              ))}
            </List>}
            <InfiniteScroll
              loadMore={async () => {await fetchNextPage()}}
              hasMore={data?.pages[data.pages.length-1]?.data.length===DEFAULT_PAGE_SIZE}
            />
          </div>
      </div>
    </div>
  )

}

export default SelectLocation;
