import { MapContainer, MapContainerProps, TileLayer } from 'react-leaflet';
import { OSM_TILE_URL } from 'config/settings';
import "leaflet/dist/leaflet.css";

interface MapProps extends MapContainerProps {

}

const Map: React.FC<MapProps> = (props) => {

  return (
    <MapContainer attributionControl={false} {...props} tap={false}>
      {props.children}
      <TileLayer url={OSM_TILE_URL} />
    </MapContainer>
  )

}

export default Map;
