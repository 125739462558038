import { Route, Routes } from "react-router"

import TicketDetail from "./detail"
import TicketEdit from "./edit"
import TicketNew from "./new"

const TicketRoute: React.FC = () => {

  return (
    <Routes>
      <Route path="new/*" element={<TicketNew />} />
      <Route path="edit/:ticketId" element={<TicketEdit />} />
      <Route path="detail/:ticketId" element={<TicketDetail />} />
    </Routes>
  )
}

export default TicketRoute
