import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

import { AuthService } from 'services';

const Login: React.FC = () => {

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(AuthService.login, {
    onSuccess: res => {
      AuthService.loginWithToken(res.data.access_token)
      navigate('/', { replace: true })
    }
  });

  return (
    <div>

      <div className="text-center pb-8">
        <div className="px-20 py-10 filter drop-shadow-xl"><img className="mx-auto" src={`${process.env.PUBLIC_URL}/logo.png`} alt="" /></div>
        <div className="text-2xl mb-2">Welcome to TravelMan</div>
        <div className="text-xs text-gray-400">Record every trip you make</div>
      </div>

      <div className="px-8">
        <Form onFinish={mutate}>
          <Form.Item name="username" rules={[{required: true, message: "Username is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" rules={[{required: true, message: "Password is not empty!"}]}>
            <Input className="bg-gray-100 rounded-lg h-12" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button className="btn-primary btn-lg" htmlType="submit" loading={isLoading} size="large" block type="primary">Login</Button>
          </Form.Item>
        </Form>
      </div>

      <div className="py-8 text-center text-gray-400 fixed bottom-0 w-full">
        Don't have an account? <span onClick={() => navigate("/register", {replace: true})} className="text-sencondary-1000">Register!</span>
      </div>

    </div>
  )

}

export default Login;
