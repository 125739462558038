import L from 'leaflet';
import { useMap } from "react-leaflet";
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import { useEffect, useState } from 'react';

interface LeafletLocateControlProps extends L.Control.LocateOptions {
  start?: boolean;
}

const LeafletLocateControl: React.FC<LeafletLocateControlProps> = (props) => {

  const map = useMap()

  const [located, setLocated] = useState<L.Control.Locate>()

  useEffect(() => {
    const locate = L.control.locate(props).addTo(map)
    setLocated(locate)
    return () => {
      located?.remove()
    }
  }, [])

  useEffect(() => {
    props.start ? located?.start() : located?.stop()
  }, [located, props.start])

  return null

}

export default LeafletLocateControl;
