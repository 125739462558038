import moment from 'moment';
import { RiChinaRailwayFill } from 'react-icons/ri';
import { TrainCompany } from 'types/ticket';

interface TrainTicketProps {
  train_no: string;
  train_company: TrainCompany;
  from_station: string;
  to_station: string;
  departure_time: string;
  arrival_time: string;
  seat?: string;
  seat_type?: string;
  price: number;
}

const TrainTicket: React.FC<TrainTicketProps> = (props) => {

  return (
    <div className="rounded-md shadow-lg bg-blue-200 bg-opacity-90">
      <div className="p-4 space-y-3">
        <div className="flex justify-between align-middle">
          <div className="text-left w-1/3">
            <div className="font-semibold text-blue-600 mb-1">出发地</div>
            <div className="text-lg">{props.from_station}</div>
          </div>
          <div className="text-4xl text-center">
            <div className="text-red-600"><RiChinaRailwayFill /></div>
            <div className="text-base">{props.train_no}</div>
          </div>
          <div className="text-right w-1/3">
            <div className="font-semibold text-blue-600 mb-1">目的地</div>
            <div className="text-lg">{props.to_station}</div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-left w-1/4">
            <div className="font-mono text-lg font-medium">{moment(props.departure_time).format("HH:mm")}</div>
            <div className="text-xs">{moment(props.departure_time).format("YYYY-MM-DD")}</div>
          </div>
          <div className="w-29 text-center">
            {/* <div className="text-base">{props.train_no}</div> */}
            <div className="text-base">{props.seat}</div>
            <div className="text-xs">{props.seat_type}</div>
          </div>
          <div className="text-right w-1/4">
            <div className="font-mono text-lg font-medium">{moment(props.arrival_time).format("HH:mm")}</div>
            <div className="text-xs">{moment(props.arrival_time).format("YYYY-MM-DD")}</div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default TrainTicket
