import { message } from 'antd';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://localhost:8000",
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
  timeout: 20 * 1000,
})

instance.interceptors.request.use(async config => {
  const token = localStorage.getItem('token')
  const newConfig = { ...config }
  if(token) {
    newConfig.headers!.Authorization = `Bearer ${token}`
  }
  return config;
}, error => {
  // error.data = {}
  // error.data.msg = '服务器异常，请联系管理员！'
  return Promise.resolve(error)
});


instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {

    console.log(error.message)

    if (!window.navigator.onLine) {
      message.error("网络已断开")
      return Promise.reject(error);
    }

    if (error.code === "ECONNABORTED") {
      message.error("请求超时")
      return Promise.reject(error);
    }

    if (error.message === "Network Error") {
      message.error("网络错误")
      return Promise.reject(error);
    }

    if(error.response.status === 400) {
      message.error(error.response.data.detail)
    }

    if (error.response.status === 401) {
      message.error(error.response.data.detail)
      window.location.replace("/login")
    }

    if (error.response.status === 403) {
      window.location.replace("/login")
    }

    if (error.response.status === 302) {
      console.log("Looooong")
    }

    // errorHandle(error.response.status, error.response.data);
    // // 错误抛到业务代码
    // error.data = {};
    // if (!window.navigator.onLine) {
    //     alert("网络已断开");
    //     error.data.msg = '网络异常，请检查网络是否正常连接';
    // } else if (error.code === "ECONNABORTED") {
    //     alert("请求超时");
    //     error.data.msg = '请求超时';
    // } else {
    //     alert("服务器异常");
    //     error.data.msg = '服务器异常，请联系管理员';
    // }
    return Promise.reject(error);
  }
);

export default instance;
