import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Popup } from 'antd-mobile';

import { MapService } from "services";
import { MapGeocodeReversed, MapLocation } from "types/map";
import SelectLocation, { SelectedParams } from "components/SelectLocation";
import gcord from 'gcoord';
interface LocationProps {
  value?: string;
  onChange?: (data: MapGeocodeReversed) => void;
}

const Location: React.FC<LocationProps> = ({ value, onChange }) => {

  const [selectVisible, setSelectVisible] = useState(false)

  const [zoom, setZoom] = useState(10)
  const [location, setLocation] = useState<MapLocation>()
  const [address, setAddress] = useState<MapGeocodeReversed>()

  const [locatedState, setLocatedState] = useState<"loading"|"error"|"success">()

  const locationReverse = useQuery(
    ["location", { lat: address?.location.lat, lng: address?.location.lng }],
    () => {
      if (location) {
        return MapService.geocoder_reverse({ lat: location.lat, lng: location.lng })
      }
    },
    {
      enabled: !!location?.lat && !!location?.lng && !address,
      onSuccess: res => {
        setAddress(res?.data)
      }
    }
  )

  useEffect(() => {
    handleOnLocated()
  }, [])

  useEffect(() => {
    if (!address || !onChange) return
    onChange?.(address)
  }, [address])

  const handleOnLocated = () => {
    setLocatedState("loading")
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocatedState("success")
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          type: gcord.CRSTypes.WGS84
        })
      },
      () => setLocatedState("error")
    )
  }

  const handleOnSelect = (data: SelectedParams) => {
    setZoom(data.zoom)
    setLocation(data.location)
    setAddress(data)
    setSelectVisible(false)
  }

  return (
    <div>
      <div onClick={() => setSelectVisible(true)} >
        {locatedState === "loading" && <div>定位中...</div>}
        {(locationReverse.isLoading || locationReverse.isFetching) && <div>获取地理位置...</div>}
        {locatedState === "error" && <div>定位失败，点击手动选择</div>}
        {locationReverse.error && <div>暂不支持该地点，请点击选择</div>}
        {address &&
          <div className="border border-gray-200 rounded-lg overflow-hidden">
            <div className="p-2 bg-white">{address.title || address.address}</div>
            <img src={MapService.get_static_map_url(address.location.lat, address.location.lng, zoom, 300, 150)} alt="" />
          </div>
        }
      </div>
      <Popup bodyClassName="h-full" destroyOnClose visible={selectVisible} position="bottom" bodyStyle={{ padding: 0 }}>
        <SelectLocation
          location={location}
          onSelected={handleOnSelect}
          onCancel={() => setSelectVisible(false)}
        />
      </Popup>
    </div>
  )

}

export default Location
