
import { useNavigate } from "react-router"

import { TicketType, TrainCompany } from "types/ticket"

import { Controller, FormProvider, useForm } from 'react-hook-form'
import TicketForm from "pages/ticket/forms"
import UINavigationBar from "components/UIKit/UINavigationBar"

const TicketNewTrain: React.FC = () => {

  const navigate = useNavigate()

  const rhf = useForm()

  const handleOnSubmit = () => {

  }

  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar
        backItem
        hideScroll
        title="添加票据"
        extra={<div onClick={rhf.handleSubmit(handleOnSubmit)}>保存</div>}
      />
      <div className="p-4">
        <FormProvider {...rhf}>
          <TicketForm
            ticketType={TicketType.train}
          />
        </FormProvider>
      </div>
    </div>

  )
}

export default TicketNewTrain;
