import { useMutation, useQuery, useQueryClient } from "react-query"
import { FootmarkService } from "services"

export const useFootmarks = () => {

  const footmarks = useQuery(["footmarks"], FootmarkService.getAll)

  return footmarks

}

export const useFootmark = (footmarkId: string) => {

  const queryClient = useQueryClient()

  const footmark = useQuery(["footmark", footmarkId], () => FootmarkService.get(footmarkId))

  const remove = useMutation(() => FootmarkService.delete(footmarkId), {
    onSuccess: () => queryClient.invalidateQueries(["footmark", footmarkId])
  })

  return { footmark, remove }

}
