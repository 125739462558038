import { Input, List, ImageUploader } from "antd-mobile"
// import { useNavigate } from "react-router"
import Form, { Field } from 'rc-field-form'
import { BiCloudUpload } from "react-icons/bi"

import { AirplaneCompany } from "types/ticket"
import { S3Service } from "services"

import UINavigationBar from "components/UIKit/UINavigationBar"

interface AirplaneFormFields {
  picture: {url: string}[];
  flight_no: string;
  airplane_company: AirplaneCompany;
  from_station: string;
  from_iata: string;
  to_station: string;
  to_iata: string;
  departure_time: string;
  arrival_time?: string;
  seat?: string;
  seat_type?: string;
  price: number;
}


const TicketNewBoat: React.FC = () => {

  // const navigate = useNavigate()

  const [ form ] = Form.useForm<AirplaneFormFields>()


  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar backItem title="New Ticket" hideScroll extra={"保存"} />
      <Form<AirplaneFormFields> form={form}>
      <div className="p-4">
        <div className="grid gap-y-4">

          <div className="rounded-lg overflow-hidden shadow-md">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix='航班号'>
                <Field name="flight_no"><Input style={{"--text-align": "right"}} /></Field>
              </List.Item>
              <List.Item prefix='日期'>
                <Input style={{"--text-align": "right"}} type="date" />
              </List.Item>
            </List>
          </div>

          <div className="rounded-lg overflow-hidden shadow-md">
            <List style={{'--prefix-width': '6em'}} className="border-0">
              <List.Item prefix="上传图片" extra={<BiCloudUpload className="text-2xl block" />} />
            </List>
            <div className="p-4 bg-white border-t rounded-md overflow-hidden">
                <Field name="picture">
                  <ImageUploader
                    upload={S3Service.qcloud_upload}
                    multiple={false}
                    maxCount={1}
                    style={{"--cell-size": "160px"}}
                  />
                </Field>
              </div>
          </div>


          <div className="rounded-lg overflow-hidden shadow-md">
            <List style={{'--prefix-width': '6em'}} className="border-0" >

              <List.Item prefix='出发地'>
                <Field name="from_station"><Input style={{"--text-align": "right"}} /></Field>
              </List.Item>

              <List.Item prefix='出发时间'>
                <Field name="departure_time"><Input style={{"--text-align": "right"}} type="datetime-local" /></Field>
              </List.Item>

              <List.Item prefix='目的地'>
                <Field name="to_station"><Input style={{"--text-align": "right"}} /></Field>
              </List.Item>

              <List.Item prefix='抵达时间'>
                <Field name="arrival_time"><Input style={{"--text-align": "right"}} type="datetime-local" /></Field>
              </List.Item>

              <List.Item prefix='座位号'>
                <Field name="seat"><Input style={{"--text-align": "right"}} /></Field>
              </List.Item>

              <List.Item prefix='座位类别'>
                <Field name="seat_type"><Input style={{"--text-align": "right"}} /></Field>
              </List.Item>

              <List.Item prefix='票价'>
                <Field name="price"><Input style={{"--text-align": "right"}} type="number" /></Field>
              </List.Item>

            </List>
          </div>

        </div>
      </div>
      </Form>
    </div>
  )
}

export default TicketNewBoat
