import { List } from "antd-mobile"
import { Route, Routes, useNavigate } from "react-router"

import UINavigationBar from "components/UIKit/UINavigationBar"

import { RiShipLine, RiTrainLine, RiAncientPavilionLine, RiBusFill } from "react-icons/ri"
import { FaPlaneDeparture } from "react-icons/fa"
import { BiCameraMovie } from "react-icons/bi"
import { GiGrandPiano, GiCastle } from "react-icons/gi"
import { MdOutlineMuseum } from "react-icons/md"

import TicketNewTrain from "./train"
import TicketNewAirplane from "./airplane"
import TicketNewThemePark from "./themepark"
import TicketNewBus from "./bus"
import TicketNewBoat from "./boat"
import TicketNewMovie from "./movie"
import TicketNewConcert from "./concert"
import TicketNewMuseum from "./museum"
import TicketNewScenicSpot from "./scenicspot"


const TicketNew: React.FC = () => {

  const navigate = useNavigate()

  return (
    <div className="min-h-full bg-gray-50">

      <Routes>
        <Route path="/" element={
          <div>
            <UINavigationBar backItem title="选择类型" hideScroll />
            <div className="px-4">
              <List mode="card">
                <List.Item prefix={<FaPlaneDeparture className="block text-xl" />} onClick={() => navigate("airplane")}>飞机票</List.Item>
                <List.Item prefix={<RiTrainLine className="block text-xl" />} onClick={() => navigate("train")}>火车票</List.Item>
                <List.Item prefix={<RiBusFill className="block text-xl" />} onClick={() => navigate("bus")}>汽车票</List.Item>
                <List.Item prefix={<RiShipLine className="block text-xl" />} onClick={() => navigate("boat")}>船票</List.Item>
              </List>
              <List mode="card">
                <List.Item prefix={<BiCameraMovie className="block text-xl" />} onClick={() => navigate("movie")}>电影票</List.Item>
                <List.Item prefix={<GiCastle className="block text-xl" />} onClick={() => navigate("themepark")}>主题乐园</List.Item>
                <List.Item prefix={<GiGrandPiano className="block text-xl" />} onClick={() => navigate("concert")}>音乐会 / 演唱会</List.Item>
              </List>
              <List mode="card">
                <List.Item prefix={<MdOutlineMuseum className="block text-xl" />} onClick={() => navigate("museum")}>博物馆</List.Item>
                <List.Item prefix={<RiAncientPavilionLine className="block text-xl" />} onClick={() => navigate("scenicspot")}>景区 / 名胜古迹</List.Item>
              </List>
            </div>
          </div>
        } />

        <Route path="train" element={<TicketNewTrain />} />
        <Route path="airplane" element={<TicketNewAirplane />} />
        <Route path="boat" element={<TicketNewBoat />} />
        <Route path="bus" element={<TicketNewBus />} />

        <Route path="movie" element={<TicketNewMovie />} />
        <Route path="concert" element={<TicketNewConcert />} />
        <Route path="themepark" element={<TicketNewThemePark />} />

        <Route path="museum" element={<TicketNewMuseum />} />
        <Route path="scenicspot" element={<TicketNewScenicSpot />} />

      </Routes>

    </div>
  )
}

export default TicketNew
