import React from "react";
import { Skeleton } from "antd";

interface SkeletonProps {
  length?: number;
  className?: string;
}

export const FootmarkListSkeleton: React.FC<SkeletonProps> = ({ className, length = 4 }) => {
  return (
    <div className={className}>
      {Array(length).fill(1).map((_, index)=><Skeleton active key={index} />)}
    </div>
  )
}

export const TicketListSkeleton: React.FC<SkeletonProps> = ({ className, length = 4 }) => {
  return (
    <div className={className}>
      {Array(length).fill(1).map((_, index)=><Skeleton active key={index} />)}
    </div>
  )
}
