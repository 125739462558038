import { Avatar } from "antd";
import { useUser } from "hooks/useUser";

interface HeaderProps {

}

const Header: React.FC<HeaderProps> = () => {

  const user = useUser()

  return (
    <div className="flex relative items-center h-16 justify-between w-full">
      <div className="flex items-center px-4 py-3 justify-between w-full z-10 fixed bg-white bg-opacity-90">
        <div className="text-xl">TravelMan</div>
        <div className="rounded-full overflow-hidden w-10 h-10">
          <Avatar size={40} src={user.data?.avatar}>{user.data?.email}</Avatar>
        </div>
      </div>
    </div>
  );
}

export default Header;
