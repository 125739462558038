import { Image } from 'antd-mobile';
import Square from 'components/Square';
import moment from 'moment';
import { MdGpsFixed } from 'react-icons/md';
import styles from './index.module.scss';

interface FootmarkCardProps {
  image: string;
  name: string;
  time: string;
  location: string;
  country: string;
}

const FootmarkCard: React.FC<FootmarkCardProps> = (props) => {

  const [lat, lng] = props.location.split(',')

  return (
    <div className={`${styles.panel} overflow-hidden border-2 border-white`}>
      <Square proportion={{width: 3, height: 2}}>
        <div className="absolute right-4 top-2">
          <span className={`flag-icon flag-icon-${props.country.toLocaleLowerCase()} w-7 h-5`} />
        </div>
        <div className="absolute left-4 top-3 flex items-center space-x-2 text-sm text-gray-500">
          <MdGpsFixed />
          <span>{lat.slice(0, 13)}, {lng.slice(0, 13)}</span>
        </div>
        <Image height="100%" width="100%" src={props.image} fit="cover" />
        <div className="px-3 py-2 absolute bottom-0 w-full bg-gray-100 bg-opacity-90">
          <div className="text-base truncate mb-2">{props.name}</div>
          <div>{moment(props.time).format("YYYY-MM-DD HH:mm")}</div>
        </div>
      </Square>
    </div>
  )

}


export default FootmarkCard;
