import { useQuery } from "react-query"
import { UserService } from "services"

export const useUser = () => {

  const user = useQuery(["user", "me"], UserService.getMyProfile)

  return user

}
