import { TicketListSkeleton } from "components/Skeleton";
import Train from "components/Tickets/Train";
import UINavigationBar from "components/UIKit/UINavigationBar";
import { useTickets } from "hooks/useTicket";
import { useNavigate } from "react-router";
import { TicketType } from "types/ticket";


const HomeTicket = () => {

  const navigate = useNavigate()

  const tickets = useTickets()

  return (
    <div className="min-h-full">
      <UINavigationBar title="Tickets" extra={<div onClick={()=>navigate("/ticket/new")}>添加</div>} />
      <div className="px-4">
        {tickets.isLoading && <div className="px-8">
          <TicketListSkeleton />
        </div>}
        {tickets.data?.map((item) => {
          return (
            <div key={item.id} className="mb-5" onClick={() => navigate(`/ticket/detail/${item.id}`)}>
              {item.ticket_type===TicketType.train && <Train {...item.ticket_data} />}
              {/* {item.ticket_type===TicketType.airplane && <Airplane {...item} />} */}
            </div>
          )
        })}
      </div>
    </div>
  )

}
export default HomeTicket
