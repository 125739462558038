import { useTicket } from "hooks/useTicket"
import moment from "moment";
import { useNavigate, useParams } from "react-router"
import UINavigationBar from "components/UIKit/UINavigationBar";
import { TicketPayload } from "types/ticket";
import { FormProvider, useForm } from "react-hook-form";
import { Toast } from "antd-mobile";

import TicketForm from "../forms";
import { useEffect } from "react";

const TicketEdit: React.FC = () => {

  const navigate = useNavigate()

  const { ticketId } = useParams();

  const rhf = useForm()

  const { ticket, update } = useTicket(ticketId!)

  useEffect(() => {
    console.log(update.error)
  }, [update.error])

  const handleOnSubmit = (values: TicketPayload) => {
    Toast.show({
      icon: "loading",
      content: "更新中..."
    })
    update.mutateAsync(values).then(
      () => {
        navigate(-1)
      }
    ).finally(() => {
      Toast.clear()
    })
  }

  return (
    <div className="min-h-full bg-gray-50">
      <UINavigationBar
        backItem
        hideScroll
        title={moment(ticket.data?.datetime_at).format("YYYY-MM-DD")}
        extra={<div onClick={rhf.handleSubmit(handleOnSubmit)}>保存</div>}
      />
      <div className="p-4">
        <FormProvider {...rhf}>
          {ticket.data && <TicketForm
            ticketType={ticket.data.ticket_type}
            datetime={ticket.data.datetime_at}
            footmarks={ticket.data.footmarks.map(item => item.id)}
            ticketData={ticket.data.ticket_data}
          />}
        </FormProvider>
      </div>

    </div>
  )
}

export default TicketEdit
